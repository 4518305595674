import { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import EmbedSafeAnchor from '@onramp/components/embedding/EmbedSafeAnchor';
import { PageWrapper } from '@onramp/components/PageWrapper';
import type { landingPageQuery } from '@onramp/data/__generated__/landingPageQuery.graphql';
import { getRoute, useOnRampRouter } from '@onramp/hooks/useOnRampRouter';
import {
  landing_page_continue_to_signin,
  landing_page_viewed,
  redirected_after_load_on_land,
} from '@onramp/utils/eventing/eventDeclarations';
import exitWidget from '@onramp/utils/exitWidget';
import { Experiments } from '@onramp/utils/experiments/experiments';
import { useLogWidgetMetricOnce } from '@onramp/utils/metrics';
import { isEmbeddedExperience, isMobileExperience } from '@onramp/utils/postMessage';
import {
  GUEST_FLOW,
  GUEST_FLOW_CONTINUE_TO_GUEST_CLICKED,
  useLogOnrampEventOnMountV2,
  useLogOnrampEventV2,
} from '@onramp/v2/client/views/guest/onramp/analytics';
import { Button } from '@cbhq/cds-web/buttons';
import { SpotSquare } from '@cbhq/cds-web/illustrations/SpotSquare';
import { VStack } from '@cbhq/cds-web/layout';
import { Spacer } from '@cbhq/cds-web/layout/Spacer';
import { Link as CDSLink, TextBody, TextLegal, TextTitle3 } from '@cbhq/cds-web/typography';
import { flushQueue } from '@cbhq/client-analytics';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import { useIsTreatment } from '@cbhq/experiments';

export const messages = defineMessages({
  pageTitle: {
    id: 'landingPage.pageTitle',
    defaultMessage: 'Coinbase Onramp',
    description: 'The title of the landing page',
  },
  title: {
    id: 'landingPage.title',
    defaultMessage: 'Buy or transfer crypto',
    description: 'The title of the body',
  },
  body: {
    id: 'landingPage.body',
    defaultMessage:
      'You can use your Coinbase account to buy or transfer any amount, or you can buy up to {fiatAmount} a week with your debit card.',
    description: 'Description of the reasons why you should signin to Coinbase',
  },
  signIn: {
    id: 'landingPage.signIn',
    defaultMessage: 'Buy or transfer with Coinbase',
    description: 'Call to action button to ask users to signin.',
  },
  continueAsGuest: {
    id: 'landingPage.continueAsGuest',
    defaultMessage: 'Buy with debit card',
    description: 'Secondary call to action button asking users to signin as guests.',
  },
  footerText: {
    id: 'landingPage.footerText',
    defaultMessage:
      "Linking a Coinbase account is subject to Coinbase's <userAgreementLink>User Agreement</userAgreementLink> and <privacyPolicyLink>Privacy Policy</privacyPolicyLink>.",
    description: 'Footer text of the signin page.',
  },
});

const query = graphql`
  query landingPageQuery {
    viewer {
      isGuestCheckoutEligible
    }
  }
`;

export default function GuestCheckoutLandingPageContents() {
  const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl') ?? undefined;
  useLogOnrampEventOnMountV2('landing_page_viewed', landing_page_viewed(redirectUrl));
  const logWidgetMetricOnce = useLogWidgetMetricOnce();
  const logGuestOnrampEvent = useLogOnrampEventV2();
  const { push } = useOnRampRouter();
  const isInUsmExperiment = useIsTreatment(Experiments.jan_2025_onramp_usm);

  const isMobile = isMobileExperience();
  const { viewer } = useLazyLoadQuery<landingPageQuery>(query, {});
  const { isGuestCheckoutEligible } = viewer;

  const { formatMessage, formatNumber } = useIntl();

  const redirectToSignin = useCallback(() => {
    const newUrl = new URL(window.location.href);
    newUrl.pathname = isInUsmExperiment ? getRoute('V2Signin') : getRoute('Signin');
    flushQueue().finally(() => {
      window.location.href = `${newUrl}`;
    });
  }, [isInUsmExperiment]);

  useEffect(() => {
    const shouldRedirect = () => {
      // isGuestCheckoutEligible is based off of user IP address, different than the server check
      if (!isGuestCheckoutEligible) {
        logGuestOnrampEvent('redirected_after_load_on_land', {
          ...redirected_after_load_on_land,
          isGuestCheckoutEligible: false,
        });
        redirectToSignin();
      }
    };
    shouldRedirect();
  }, [isGuestCheckoutEligible, redirectToSignin, logGuestOnrampEvent]);

  const handleSignIn = useCallback(() => {
    logGuestOnrampEvent('landing_page_continue_to_signin', landing_page_continue_to_signin);
    logWidgetMetricOnce({
      metricName: 'landing',
      value: 1,
      tags: { step: 'select-login' },
    });
    redirectToSignin();
  }, [redirectToSignin, logWidgetMetricOnce, logGuestOnrampEvent]);

  const handleContinueWithGuest = useCallback(() => {
    logGuestOnrampEvent(GUEST_FLOW, GUEST_FLOW_CONTINUE_TO_GUEST_CLICKED);
    logWidgetMetricOnce({
      metricName: 'landing',
      value: 1,
      tags: { step: 'select-guest-checkout' },
    });
    push('V2.OnrampInput');
  }, [push, logWidgetMetricOnce, logGuestOnrampEvent]);

  const shouldShowBackButton = useMemo(() => isMobile || isEmbeddedExperience(), [isMobile]);

  return (
    <PageWrapper
      headerText={formatMessage(messages.pageTitle)}
      onBack={shouldShowBackButton ? exitWidget : undefined}
      borderedHeader
    >
      <VStack height="100%" spacingHorizontal={3} spacingBottom={3} justifyContent="space-between">
        <VStack>
          <SpotSquare name="assetForward" />
          <TextTitle3 as="p" spacingBottom={1}>
            {formatMessage(messages.title)}
          </TextTitle3>
          <TextBody as="p" align="start" spacingBottom={4} color="foregroundMuted">
            {formatMessage(messages.body, {
              fiatAmount: formatNumber(500, { style: 'currency', currency: 'USD' }),
            })}
          </TextBody>
        </VStack>
        <VStack>
          <Button block onPress={handleSignIn}>
            {formatMessage(messages.signIn)}
          </Button>
          <Spacer vertical={2} />
          <Button variant="secondary" block onPress={handleContinueWithGuest}>
            {formatMessage(messages.continueAsGuest)}
          </Button>
          <Spacer vertical={2} />
          <TextLegal as="p" color="foregroundMuted" align="center">
            {formatMessage(messages.footerText, { userAgreementLink, privacyPolicyLink })}
          </TextLegal>
        </VStack>
      </VStack>
    </PageWrapper>
  );
}

const userAgreementLink = (text: string) => (
  <EmbedSafeAnchor href="https://www.coinbase.com/legal/user_agreement">
    <CDSLink underline={false}>{text}</CDSLink>
  </EmbedSafeAnchor>
);

const privacyPolicyLink = (text: string) => (
  <EmbedSafeAnchor href="https://www.coinbase.com/legal/privacy">
    <CDSLink underline={false}>{text}</CDSLink>
  </EmbedSafeAnchor>
);
